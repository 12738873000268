/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/es5-shim@4.6.7/es5-shim.min.js
 * - /npm/es5-shim@4.6.7/es5-sham.min.js
 * - /npm/html5shiv@3.7.3/dist/html5shiv-printshiv.min.js
 * - /npm/respond.js@1.4.2/dest/respond.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
